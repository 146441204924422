.menu {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 8vh;
    background-color: var(--menu-bg-color);
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-top: 1px solid #333;
}

.menuItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--menu-icon-color);
    cursor: pointer;
    transition: transform 0.2s ease, opacity 0.7s ease;
    box-sizing: border-box;

    &:hover {
        color: var(--menu-active-icon-color);
    }

    &:active {
        transform: scale(1.2);
    }
}

.menuItemActive {
    color: var(--menu-active-icon-color);
}

.menuLabel {
    margin-top: 4px;
    font-size: 1.5vh;
    line-height: 1rem;
}