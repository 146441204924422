/* src/screens/BasketScreen/BasketScreen.module.scss */
.container {
  padding: 20px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  background-color: var(--basket-screen-bg-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.upperBlock {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
}

.title {
  font-family: Druk Wide Cyr;
  font-size: 3vh;
  font-weight: 600;
  margin-bottom: 20px;
  color: var(--basket-screen-text-color);
  width: 100%;
}

.empty {
  font-size: 2vh;
  color: #666;
  text-align: center;
  margin-top: 20px;
}

.items {
  margin-bottom: 20px;
  color: var(--basket-screen-text-color);
  font-family: Manrope;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  box-sizing: border-box;
}

.submitButton {
  display: block;
  font-family: Druk Wide Cyr;
  width: 100%;
  padding: 15px;
  background-color: var(--basket-screen-btn-color);
  color: var(--basket-screen-text-color);
  font-size: 2vh;
  font-weight: 600;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  justify-self: flex-end;
}

.submitButton:hover {
  background-color: darken(#FF4D00, 10%);
}

.error {
  color: red;
  text-align: center;
  margin-top: 10px;
}