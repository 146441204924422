.App {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  background: var(--background-color);
}

.content {
  flex-grow: 1;
  padding-bottom: 8vh;
  max-height: calc(100vh - 8vh);
  overflow-y: hidden;
}

.full {
  padding-bottom: 0;
  max-height: 100vh;
}

@font-face {
  font-family: Manrope;
  src: url(./fonts/Manrope/Manrope-VariableFont_wght.ttf);
}

@font-face {
  font-family: Druk Wide Cyr;
  src: url(./fonts/Druk\ Wide\ Cyr/fonts/drukwidecyr-bold.ttf);
}

:root {
  --background-color: #262626;
  --menu-bg-color: #1e1e1e;
  --card-bg-color: white;
  --text-color: white;
  --card-text: #3E3E3E;
  --card-price-color: #3E3E3E;
  --card-name-color: #3E3E3E;
  --block-bg-color: #3E3E3E;
  --basket-bg-color: #3E3E3E;
  --like-color: #FF4D00;
  --addColor: #FF4D00;
  --filter-bg-color: #262626;
  --filter-option-bg-color: #3E3E3E;
  --filter-option-text-color: #fff;
  --filter-text-color: #aaa;
  --filter-button-color: #FF4D00;
  --remove-color: #8b330d;
  --menu-icon-color: #aaa;
  --menu-active-icon-color: #FF4D00;
  --basket-screen-bg-color: #1e1e1e;
  --basket-screen-text-color: #aaa;
  --basket-screen-btn-color: #FF4D00;
  --load-color: #1e1e1e;
}