.like {
    position: absolute;
    right: 5px;
    top: 5px;
    transition: transform 0.2s ease, opacity 0.7s ease;
}

.like:active {
    transform: scale(1.2);
}
.likePage {
    transition: transform 0.2s ease, opacity 0.7s ease;
}
.likePage:active {
    transform: scale(1.3);

}