@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.container {

    font-family: Manrope;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow-y: scroll;
    overflow-x: hidden;
    /*transition: opacity 1s cubic-bezier(0.42, 0, 0.58, 1);*/
    //animation: slideIn 0.5s ease-in-out forwards; /* Плавный переход для изменения прозрачности */
}

.visible {
    opacity: 1;
}

._ {
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    top: 0;
    left: 0;
    z-index: 3;
    background: var(--background-color);
    overflow: auto;
    padding: 10px;
    display: flex;
    gap: 10px;
    flex-direction: column;
}

.block1 {
    background: var(--background-color);
    border-radius: 10px;
}

.image {
    border-radius: 10px;
    width: 100%;
}

.name {
    font-family: Druk Wide Cyr;
    font-weight: 600;
    font-size: 2vh;
    color: var(--text-color);
    padding: 10px 10px 0 10px;
    width: 70%;
    line-height: 2.2vh;
    text-align: left;
    width: 90%;
}

.block1_2 {
    display: flex;
    justify-content: space-between;
    padding: 15px 10px 15px 10px;
    align-items: anchor-center;
}

.address {
    font-weight: 400;
    font-size: 1vh;
    color: var(--text-color);
}

.btnsBlock {
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.price {
    font-family: Druk Wide Cyr;
    font-weight: 700;
    font-size: 2vh;
    color: var(--text-color);
    white-space: nowrap;
}

.like {
    transition: opacity .3s;
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
}

.infoBlock {
    position: relative;
    display: flex;
    flex-direction: column;
}

.colorSizeLine {
    display: flex;
    gap: 10px;
    flex-direction: row;
    justify-content: space-evenly;
    box-sizing: border-box;

}

.coloSizeSelector {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 10px 10px;
    background: var(--block-bg-color);
    width: 40%;
    justify-content: center;
    box-sizing: border-box;
}

.colorSizeValue {
    color: var(--text-color);
    font-size: 1.5vh;
    font-weight: 600;
    text-align: center;
}

.colorSizeLabel {
    color: var(--text-color);
    font-size: 1.2vh;
    font-weight: 500;
    text-align: center;
}

.busketLine {
    font-family: Druk Wide Cyr;
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 15px;
    box-sizing: border-box;

}

.infoText {
    font-weight: 400;
    font-size: 1.5vh;
    color: var(--text-color);
}

.infoText2 {
    font-weight: 700;
    font-size: 1.5vh;
    color: var(--text-color);
}

.block2_1 {
    display: flex;
    gap: 2px;
    flex-direction: column;
    width: calc(45%);
    text-align: center;
}

.block3 {
    background: var(--block-bg-color);
    border-radius: 10px;
    padding: 15px 10px;
}

.infoText3 {
    font-weight: 600;
    font-size: 1.5vh;
    color: var(--text-color);
}

.infoText4 {
    font-weight: 400;
    font-size: 1.5vh;
    text-align: justify;
    color: var(--text-color);
    opacity: 0.9;
    padding-top: 10px;
}

.block3_1 {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 10px;
    flex-direction: column;
}

.iconBlock {
    font-weight: 400;
    font-size: 1.5vh;
    display: flex;
    gap: 5px;
    color: var(--text-color);
}

.icon {
    height: 20px;
}