.container {
    padding: 20px;
    height: 100%;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    background-color: var(--basket-screen-bg-color);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.title {
    font-family: Druk Wide Cyr;
    font-size: 3vh;
    font-weight: 600;
    margin-bottom: 20px;
    color: var(--basket-screen-text-color);
    width: 100%;
}

.ordersList {
    margin-bottom: 20px;
    color: var(--basket-screen-text-color);
    font-family: Manrope;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    box-sizing: border-box;
}