// src/components/ImageCard/ImageCard.module.scss
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 70%;
  }
.imageBox {
    position: relative;
    width: 100%;
    height: 70%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.imageBoxFull {
  height: 100%;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 6px 6px 0 0;
}
.thumbnails {
    display: flex;
    justify-content: center;
    gap: 5px;
    margin-top: 10px;
  }
  
  .thumbnail {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    max-width: 50px;
    max-height: 50px;
    object-fit: cover;
    border-radius: 4px;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .thumbnail:hover {
    transform: scale(1.1);
  }
  
  .activeThumbnail {
    border: 2px solid var(--primary-color);
  }
  .thumbnailWrapper {
    position: relative;
    cursor: pointer;
  }
  
  .playOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
  }
  
  .playIcon {
    width: 24px;
    height: 24px;
    fill: white;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
  }