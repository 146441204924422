/* src/components/Dropdown/Dropdown.module.scss */
.dropdown {
  position: relative;
  width: 100%;
  user-select: none;
  font-family: Manrope;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 12px;
  background-color: var(--filter-option-bg-color);
  border: 1px solid var(--filter-option-bg-color);
  border-radius: 4px;
  cursor: pointer;
}

.headerText {
  flex: 1;
  font-size: 1.5vh;
  color: var(--filter-option-text-color);
}

.arrow {
  font-size: 1.5vh;
  color: #666;
  margin-left: 10px;
}

.options {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  width: 100%;
  max-height: 20vh;
  background-color: var(--filter-option-bg-color);
  border: 1px solid var(--filter-option-bg-color);
  border-radius: 4px;
  overflow-y: auto;
  z-index: 10;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}

.option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.option:hover {
  background-color: #f0f0f0;
}

.optionLabel {
  font-size: 1.5vh;
  color: var(--filter-option-text-color);
}

.marker {
  font-size: 16px;
  color: var(--primary-color);
}