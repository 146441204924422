/* src/components/BasketItem/BasketItem.module.scss */
.item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  gap: 5px;
  align-items: center;
  padding: 10px 0px;
  border-bottom: 1px solid #ddd;
}

.photoblock {
  width: 23%;
  max-width: 7vh;
  max-height: 7vh;
  box-sizing: border-box;
  border-radius: 12px;
  overflow: hidden;
}

.details {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

.digitBlock {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  gap: 30px;
}

.name {
  font-size: 2vh;
  font-weight: 500;
  margin-bottom: 5px;
  width: 100%;
  text-align: end;
}

.quantityControls {
  display: flex;
  align-items: center;
}

.button {
  width: 2vh;
  height: 2vh;
  border: none;
  background-color: var(--primary-color);
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
  font-size: 2vh;
  line-height: 2vh;
  font-family: Druk Wide Cyr;
}

.quantity {
  margin: 0 10px;
  font-size: 2vh;
}

.priceSection {
  text-align: right;
}

.price {
  font-size: 2vh;
  font-weight: 600;
}

.originalPrice {
  font-size: 2vh;
  color: #888;
}

.discountedPrice {
  font-size: 2vh;
  font-weight: 600;
  color: var(--primary-color);
}