/* src/components/Filters/Filters.module.scss */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: var(--filter-bg-color);
  width: 90%;
  border-radius: 8px;
  position: relative;
  padding: 20px 20px 30px 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  //overflow-y: auto;
  box-sizing: border-box;
}

.closeButton {
  position: absolute;
  top: 1vh;
  right: 1vh;
  border: none;
  background: transparent;
  font-size: 3vh;
  cursor: pointer;
  line-height: 1;
  color: var(--filter-text-color);
}

.title {
  font-family: Druk Wide Cyr;
  text-align: center;
  margin-bottom: 20px;
  color: var(--filter-text-color);
  font-size: 2.8vh;
}

.filterGroup {
  margin-bottom: 2vh;
  color: var(--filter-text-color);
}

.label {
  font-family: Druk Wide Cyr;
  margin-bottom: 8px;
  display: block;
  font-size: 2vh;
}

.option {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.option input {
  margin-right: 8px;
}

.resetButton {
  display: block;
  width: 100%;
  padding: 1vh;
  background-color: var(--filter-button-color);
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1.5vh;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.resetButton:hover {
  background-color: darken(#FF4D00, 10%);
}