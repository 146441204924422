/* src/components/Success/Success.module.scss */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(5px);
    z-index: 1000;
  }
  
  .successContainer {
    padding: 20px 30px;
    background-color: var(--success-bg, #4caf50);
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0,0,0,0.1);
  }
  
  .message {
    font-family: Druk Wide Cyr;
    font-size: 16px;
    font-weight: bold;
    color: var(--success-text, #fff);
    text-align: center;
  }
  