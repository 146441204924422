// src/components/Card/Card.module.scss
.card {
    background: var(--card-bg-color);
    color: var(--card-text);
    border-radius: 12px;
    width: calc(50% - 5px);
    height: 25vh;
    position: relative;
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    font-family: 'Manrope';
    padding-bottom: 10px;
}

.info {
    padding: 5px;
    gap: 5px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.block {
    display: flex;
    justify-content: space-between;
}

.blockName {
    display: flex;
    width: 100%;
    font-weight: 600;
    font-size: 1.8vh;
    color: var(--card-name-color);
    box-sizing: border-box;
}

.price {
    font-family: Druk Wide Cyr;
    font-weight: 600;
    font-size: 2vh;
    color: var(--card-price-color);
    margin: 0;
}

.loc {
    font-weight: 600;
    font-size: 12px;
    color: var(--card-name-color);
    margin: 0;
}