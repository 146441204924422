/* src/components/ErrorMessage/ErrorMessage.module.scss */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(5px);
  z-index: 1000;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.errorMessage {
  font-family: Druk Wide Cyr;
    background-color: #f44336; /* красный фон */
    color: #fff;
    padding: 12px 20px;
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(0,0,0,0.2);
    z-index: 1100;
    font-size: 16px;
    text-align: center;
    opacity: 0.95;
    transition: opacity 0.5s ease-out;
  }
  