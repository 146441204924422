.catalogContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
    padding: 10px;
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
}

.scrollToTopButton {
    position: absolute;
    bottom: 20px;
    right: 30px;
    z-index: 1000;
    cursor: pointer;
    transition: opacity 0.3s;
    opacity: 0;
}

.scrollToTopButton.show {
    opacity: 1;
}

.scrollToTopButton.hide {
    opacity: 0;
}