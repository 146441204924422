.orderItem {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    flex-direction: column;
    border-bottom: 3px solid #fff;
    padding-bottom: 5px;
    margin-bottom: 15px;
}

.header {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;

    .title {
        font-family: Druk Wide Cyr;
        font-size: 1.5vh;
        font-weight: 600;
        color: var(--basket-screen-text-color);
        width: 100%;
    }

    .date {
        font-family: Druk Wide Cyr;
        font-size: 1.3vh;
        font-weight: 600;
        color: var(--basket-screen-text-color);
        width: 100%;
        text-align: end;
        box-sizing: border-box;
    }
}

.status {
    font-family: Druk Wide Cyr;
    font-size: 1.2vh;
    display: flex;
    flex-direction: row;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
}

.items {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 10px;

    .orderPosition {
        display: flex;
        width: 100%;
        box-sizing: border-box;
        flex-direction: row;
        justify-content: space-between;
        font-size: 1.4vh;

        .imageBlock {
            width: 7vh;
            height: 7vh;
            align-self: center;
        }

        .positionHeader {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            box-sizing: border-box;
            width: 85%;

            .name {
                width: 60%;
            }

            .amountPriceBlock {
                width: 40%;
                text-align: end;
                align-self: center;
                display: flex;
                flex-direction: column;
                box-sizing: border-box;

                .amount {}

                .price {
                    font-family: Druk Wide Cyr;
                    font-size: 1.2vh;
                    justify-content: flex-end;

                }
            }

        }

    }
}

.totalSum {
    display: flex;
    width: 100%;

}

.cancelButton {
    font-family: Druk Wide Cyr;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--remove-color);
    color: #fff;
    border-radius: 10px;
    padding: 5px 0px;
}