.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(5px);
  z-index: 1000;
}

.loaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loader {
  width: 10vh;
  height: 10vh;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top: 5px solid var(--like-color, #007bff);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.message {
  font-family: Druk Wide Cyr;
  margin-top: 1rem;
  font-size: 2rem;
  font-weight: bold;
  color: var(--load-color);
  text-align: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}